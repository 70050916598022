export const copyDetails = (input: any): boolean => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(JSON.stringify(input));
  } else {
    console.info('Browser Not compatible');
    // alert('Browser Not compatible');
  }
  return false;
}

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms));