export const modalTitleStorageTest = 'Checking the connection to your destination';
export const modalTitleRefreshTokenCreation = 'Create an API refresh token';
export const modalTitleRefreshTokenRevoke = 'Revoke an API refresh token';
export const modalTitleHistoryCancel = "Cancelling History";

export const modalMessageRefreshTokenCreation = 'Saving your refresh token';
export const modalMessageRefreshTokenRevoke = 'Revoking your refresh token';
export const modalMessageStorageTestInitialize = 'Checking the connection to your destination';
export const modalMessageStorageTestAccMapping = 'Submitting encrypted credentials to your data destination';
export const modalMessageStorageTestSubmission = 'Connection test to the target data destination has started';
export const modalMessageStorageTestPolling = 'We are waiting for connection testing to complete. A test can take up to 5 minutes; please be patient and do not refresh or leave this screen';
export const modalMessageStorageTestComplete = 'Data destination connection testing has completed';
export const modalMessageHistoryCancel = "Cancelling current history requests for subscription ";



