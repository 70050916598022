import { errorWizardGeneralError } from './../app/shared/constants/flash-notifications';
import { rest } from 'msw';
import { findRequestURL, findSubscriptionFromSubscriptionId, findSubscriptionFromSubscriptionName } from '../mocks/function';
import { responseSubscriptionExistError } from './data/response-subscription-exist-error';
import { responseFacebookMarketingProfiles } from './data/facebook-marketing/response-facebook-marketing-profiles';
import { responseFacebookMarketingSubscription } from './data/facebook-marketing/response-facebook-marketing-subscription';
import { responseFacebookCommonProfiles } from './data/facebook-common/response-facebook-common-profiles';
import { responseFacebookCommonSubscription } from './data/facebook-common/response-facebook-common-subscription';
import { responseGoogleAdsCustomerList } from './data/google-ads/response-google-ads-customer-list';
import { responseGoogleAdsCustomerListManaged } from './data/google-ads/response-google-ads-customer-list-managed';
import { responseGoogleAdsSubscription } from './data/google-ads/response-google-ads-subscription';
import { responseGoogleAdsSpm } from './data/google-ads/response-google-ads-spm';
import { responseHealthchecksData } from './data/response-healthcheck';
import { responseGoogleAdsEditSubscription } from './data/google-ads/response-google-ads-edit-subscription';
import { responseSpMarketplaces } from './data/amazon-sp-products/response-sp-marketplaces';
import { responseSpSubscription } from './data/amazon-sp-products/response-sp-subscription';
import { responseAmazonAttributionSpm } from './data/amazon-attribution/response-amazon-attribution-spm';
import { responseAmazonAttributionEditSubscription } from './data/amazon-attribution/response-amazon-attribution-edit-subscription';

import { responseGoogleCampaignManagerSubscription } from './data/google-campaign-manager/google-campaign-manager-subscription';
import { responseGoogleCampaignManagerProfiles } from './data/google-campaign-manager/google-campaign-manager-profiles';
import { responseGoogleCampaignManagerReports } from './data/google-campaign-manager/google-campaign-manager-reports';

import { responseGoogleAnalytics360Datasets } from './data/google-analytics-360/response-google-analytics-360-datasets';
import { responseGoogleAnalytics360Projects } from './data/google-analytics-360/response-google-analytics-360-projects';
import { responseGoogleAnalytics360Subscription } from './data/google-analytics-360/response-google-analytics-360-subscription';
import { responseGoogleAnalytics360GaTableExists } from './data/google-analytics-360/response-google-analytics-360-ga-table-exists';

import { responseGoogleSearchAds360Profiles } from './data/google-search-ads-360/response-google-search-ads-360-profiles';
import { responseGoogleSearchAds360Subscription } from './data/google-search-ads-360/response-google-search-ads-360-subscription';
import { responseUserDetailsUpdate } from './data/response-user-details-update';
import { responsePasswordChange } from './data/response-password-change';
import { responseAmazonDSPSubscription } from './data/amazon-dsp/response-amazon-dsp-subscriptions';
import { responseAmazonDSPBrands } from './data/amazon-dsp/response-amazon-dsp-brand';
import { responseAmazonDSPProfiles } from './data/amazon-dsp/response-amazon-dsp-profiles';
import { responseAmazonDspEditSubscription } from './data/amazon-dsp/response-amazon-dsp-edit-subscription';
import { responseAmazonDspSpm } from './data/amazon-dsp/response-amazon-dsp-spm';

import { responseAmazonFeesSpm } from './data/amazon-fees/response-amazon-fees-spm';
import { responseAmazonFeesEditSubscription } from './data/amazon-fees/response-amazon-fees-edit-subscription';
import { responseAmazonFinanceRealtimeEditSubscription } from './data/amazon-finance-realtime/response-amazon-finance-realtime-edit-subscription';
import { responseAmazonFinanceRealtimeSpm } from './data/amazon-finance-realtime/response-amazon-finance-realtime-spm';
import { responseAmazonFulfillmentRealtimeEditSubscription } from './data/amazon-fulfillment/response-amazon-fulfillment-edit-subscription';
import { responseAmazonFulfillmentRealtimeSpm } from './data/amazon-fulfillment/response-amazon-fulfillment-spm';
import { responseAmazonInboundFulfillmentSpm } from './data/amazon-inbound-fulfillment/response-amazon-inbound-fulfillment-spm';
import { responseAmazonInboundFulfillmentEditSubscription } from './data/amazon-inbound-fulfillment/response-amazon-inbound-fulfillment-edit-subscription';
import { responseInstagramInsightsSpm } from './data/instagram-insights/response-instagram-insights-spm';
import { responseInstagramInsightsEditSubscription } from './data/instagram-insights/response-instagram-insights-edit-subscription';
import { responseAmazonInventorySpm } from './data/amazon-inventory/response-amazon-inventory-spm';
import { responseAmazonInventoryEditSubscription } from './data/amazon-inventory/response-amazon-inventory-edit-subscription';
import { responseAmazonInventoryRealtimeEditSubscription } from './data/amazon-inventory-realtime/response-amazon-inventory-realtime-edit-subscription';
import { responseAmazonInventoryRealtimeSpm } from './data/amazon-inventory-realtime/response-amazon-inventory-realtime-spm';
import { responseFacebookMarketingEditSubscription } from './data/facebook-marketing/response-facebook-marketing-edit-subscription';
import { responseFacebookMarketingSpm } from './data/facebook-marketing/response-facebook-marketing-spm';
import { responseAmazonOrdersSpm } from './data/amazon-orders/response-amazon-orders-spm';
import { responseAmazonOrdersEditSubscription } from './data/amazon-orders/response-amazon-orders-edit-subscription';
import { responseFacebookPageInsightsEditSubscription } from './data/facebook-page-insights/response-facebook-page-insights-edit-subscription';
import { responseFacebookPageInsightsSpm } from './data/facebook-page-insights/response-facebook-page-insights-spm';
import { responseAmazonSalesEditSubscription } from './data/amazon-sales/response-amazon-sales-edit-subscription';
import { responseAmazonSalesSpm } from './data/amazon-sales/response-amazon-sales-spm';
import { responseAmazonSettlementReportsEditSubscription } from './data/amazon-settlement-reports/response-amazon-settlement-reports-edit-subscription';
import { responseAmazonSettlementReportsSpm } from './data/amazon-settlement-reports/response-amazon-settlement-reports-spm';
import { responseInstagramStoriesEditSubscription } from './data/instagram-stories/response-instagram-stories-edit-subscription';
import { responseInstagramStoriesSpm } from './data/instagram-stories/response-instagram-stories-spm';
import { responseDataLakeEditSubscription } from './data/azure-data-lake/response-data-lake-edit-subscription';
import { responseDataLakeSpm } from './data/azure-data-lake/response-data-lake-spm';
import { responseAthenaSpm } from './data/aws-athena/response-athena-spm';
import { responseAthenaEditSubscription } from './data/aws-athena/response-athena-edit-subscription';
import { responseBlobSpm } from './data/azure-blob-storage/response-blob-spm';
import { responseBlobEditSubscription } from './data/azure-blob-storage/response-blob-edit-subscription';
import { responseSnowflakeSpm } from './data/snowflake/response-snowflake-spm';
import { responseSnowflakeEditSubscription } from './data/snowflake/response-snowflake-edit-subscription';
import { responseBigQuerySpm } from './data/big-query/response-big-query-spm';
import { responseBigQueryEditSubscription } from './data/big-query/response-big-query-edit-subscription';

import { responseBigQuerySubscription } from './data/big-query/response-big-query-subscription';
import { responseAmazonAdvertizingSpm } from './data/amazon-advertising/response-amazon-advertizing-spm';
import { responseAmazonAdvertizingEditSubscription } from './data/amazon-advertising/response-amazon-advertizing-edit-subscription';
import { responseAmazonAdvertisingSubscription } from './data/amazon-advertising/response-amazon-advertising-subscription';
import { responseAmazonAdvertisingBrands } from './data/amazon-advertising/response-amazon-advertising-brand';
import { responseAmazonAttributionReportTest } from './data/amazon-attribution/response-amazon-attribution-report-test';
import { responseAmazonAttributionSubscription } from './data/amazon-attribution/response-amazon-attribution-subscription';
import { responseAmazonAttributionBrands, responseAmazonAttributionBrands2 } from './data/amazon-attribution/response-amazon-attribution-brand';
import { responseAmazonAdvertisingProfiles } from './data/amazon-advertising/response-amazon-advertising-profiles';
import { responseBigQueryDataSet } from './data/big-query/response-big-query-dataset';
import { responseBigQueryRemoteIdentityMeta } from './data/big-query/response-big-query-identity-meta';
import { responseBigQueryRemoteIdentity } from './data/big-query/response-big-query-remote-identity';
import { responseBigQueryValidationEncryption } from './data/big-query/response-big-query-validation-encryption';
import { responseBigQueryValidation } from './data/big-query/response-big-query-validation';
import { responseCustomerDetails } from './data/response-customer-details';
import { responsePipelineCount } from './data/response-pipeline-count';
import { responseSubscriptions } from './data/response-subscriptions';
import { responseSharedRemoteIdentities } from './data/response-shared-remote-identity';
import { responseAthenaAccountMappingEncryption } from './data/aws-athena/response-athena-account-mapping-encryption';
import { responseBlobAccountMappingEncryption } from './data/azure-blob-storage/response-blob-account-mapping-encryption';
import { responseDataLakeAccountMappingEncryption } from './data/azure-data-lake/response-data-lake-account-mapping-encryption';
import { responseAthenaStorages } from './data/aws-athena/response-athena-storages';
import { responseBlobStorages } from './data/azure-blob-storage/response-blob-storages';
import { responseDataLakeStorages } from './data/azure-data-lake/response-data-lake-storages';
import { responseAthenaStorage } from './data/aws-athena/response-athena-storage';
import { responseBlobStorage } from './data/azure-blob-storage/response-blob-storage';
import { responseDataLakeStorage } from './data/azure-data-lake/response-data-lake-storage';
import { responseAthenaEncryption } from './data/aws-athena/response-athena-encryption';
import { responseBlobEncryption } from './data/azure-blob-storage/response-blob-encryption';
import { responseDataLakeEncryption } from './data/azure-data-lake/response-data-lake-encryption';
import { responseAthenaAccountMapping } from './data/aws-athena/response-athena-account-mapping';
import { responseBlobAccountMapping } from './data/azure-blob-storage/response-blob-account-mapping';
import { responseDataLakeAccountMapping } from './data/azure-data-lake/response-data-lake-account-mapping';
import { responseAthenaSubscription } from './data/aws-athena/response-athena-subscription';
import { responseBlobSubscription } from './data/azure-blob-storage/response-blob-subscription';
import { responseDataLakeSubscription } from './data/azure-data-lake/response-data-lake-subscription';
import { responseAmazonAttributionProfiles } from './data/amazon-attribution/response-amazon-attribution-profiles';

import { responseBigQueryAccountMappingEncryption } from './data/big-query/response-big-query-account-mapping-encryption';
import { responseBigQueryAccountMapping } from './data/big-query/response-big-query-account-mapping';
import { responseBigQueryStorages } from './data/big-query/response-big-query-storages';
import { responseBigQueryStorage } from './data/big-query/response-big-query-storage';

import { responseSnowflakeAccountMappingEncryption } from './data/snowflake/response-snowflake-account-mapping-encryption';
import { responseSnowflakeAccountMapping } from './data/snowflake/response-snowflake-account-mapping';
import { responseSnowflakeEncryption } from './data/snowflake/response-snowflake-encryption';
import { responseSnowflakeStorage } from './data/snowflake/response-snowflake-storage';
import { responseSnowflakeStorages } from './data/snowflake/response-snowflake-storages';
import { responseSnowflakeSubscription } from './data/snowflake/response-snowflake-subscription';

import { responseRedshiftAccountMappingEncryption } from './data/aws-redshift/response-redshift-account-mapping-encryption';
import { responseRedshiftAccountMapping } from './data/aws-redshift/response-redshift-account-mapping';
import { responseRedshiftEncryption } from './data/aws-redshift/response-redshift-encryption';
import { responseRedshiftStorage } from './data/aws-redshift/response-redshift-storage';
import { responseRedshiftStorages } from './data/aws-redshift/response-redshift-storages';
import { responseRedshiftSubscription } from './data/aws-redshift/response-redshift-subscription';

import { responseDatabricksAccountMappingEncryption } from './data/databricks/response-databricks-account-mapping-encryption';
import { responseDatabricksAccountMapping } from './data/databricks/response-databricks-account-mapping';
import { responseDatabricksStorages } from './data/databricks/response-databricks-storages';
import { responseDatabricksStorage } from './data/databricks/response-databricks-storage';
import { responseDatabricksEncryption } from './data/databricks/response-databricks-encryption';
import { responseDatabricksSubscription } from './data/databricks/response-databricks-subscription';
import { responseDatabricksSpm } from './data/databricks/response-databricks-spm';
import { responseDatabricksEditSubscription } from './data/databricks/response-databricks-edit-subscription';

import { responseDelta } from './data/response-delta';
import { responseToken } from './data/response-token';
import { responseJwt } from './data/response-jwt';
import { responseAccount } from './data/response-account';
import { responseUser } from './data/response-user';
// export const handlers = [];

export const handlers = [
  // public API used in identities unit test for initial MSW test
  // rest.get('https://cat-fact.herokuapp.com/facts/', (req, res, ctx) => {
  //   return res(
  //     ctx.status(200),
  //     ctx.json({
  //       id: 'test-id-001',
  //       firstName: 'John',
  //       lastName: 'Doe',
  //     }),
  //   );
  // }),

  // token
  // rest.post(findRequestURL('tokenURL'), (req, res, ctx) => {
  //   const clientId = req.url.searchParams.get('client_id');
  //   // 2RQxdFxBYybN45S0w2R4plVeP1yUguZz
  //   return res(
  //     ctx.status(200),
  //     ctx.json(responseToken),
  //   );
  // }),

  // jwt
  rest.get(findRequestURL('jwtURL'), (req, res, ctx) => {
    return res(
      ctx.status(202),
      ctx.json(responseJwt),
    );
  }),

  // account
  rest.get(findRequestURL('accountURL'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(responseAccount),
    );
  }),

  // user
  rest.get(findRequestURL('userURL'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(responseUser),
    );
  }),

  // customer details
  rest.get(findRequestURL('stripeCustomer'), (req, res, ctx) => {
    const user = req.params;
    return res(
      ctx.delay(1000),
      ctx.status(200),
      ctx.json(responseCustomerDetails),
    );
  }),

  // deltas
  rest.get(findRequestURL('deltaURL'), (req, res, ctx) => {
    const id = req.params.id;
    const timestamp = req.url.searchParams.get('timestamp');
    return res(
      ctx.status(200),
      ctx.json(responseDelta),
    );
  }),

  // pipe-line count
  rest.get(findRequestURL('pipelineURL'), (req, res, ctx) => {
    const query = req.url.searchParams;
    const date = query.get('date');
    return res(
      ctx.status(200),
      ctx.json(responsePipelineCount),
    );
  }),

  // Shared remote identity get
  rest.get(findRequestURL('sharedIdentitiesURL'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(responseSharedRemoteIdentities),
    );
  }),

  // user data update
  rest.post(findRequestURL('userUpdateURL'), (req, res, ctx) => {
    const id = req.params.id;
    return res(
      ctx.delay(3000),
      ctx.status(200),
      ctx.json(responseUserDetailsUpdate),
    );
  }),

  // password change
  rest.post(findRequestURL('passwordChangeURL'), (req, res, ctx) => {
    return res(
      ctx.delay(3000),
      ctx.status(200),
      ctx.json(responsePasswordChange),
    );
  }),

  // subscription
  rest.get(findRequestURL('subscriptionURI'), (req, res, ctx) => {
    const page = req.url.searchParams.get('page');
    const pageSize = req.url.searchParams.get('page_size');

    return res(
      ctx.status(200),
      ctx.json(responseSubscriptions),
    );
  }),

  // account mapping encryption
  rest.post(findRequestURL('accMappingEncryptionURI'), (req, res, ctx) => {
    const data: any = req.body['data'] || null;
    const storageName: string = data.attributes.storage;

    switch (storageName) {
      case 'athena': {
        return res(
          ctx.status(200),
          ctx.json(responseAthenaAccountMappingEncryption),
        );
      }
      case 'azure_blob': {
        return res(
          ctx.status(200),
          ctx.json(responseBlobAccountMappingEncryption),
        );
      }
      case 'azure_datalake': {
        return res(
          ctx.status(200),
          ctx.json(responseDataLakeAccountMappingEncryption),
        );
      }
      case 'snowflake': {
        return res(
          ctx.status(200),
          ctx.json(responseSnowflakeAccountMappingEncryption),
        );
      }
      case 'redshift': {
        return res(
          ctx.status(200),
          ctx.json(responseRedshiftAccountMappingEncryption),
        );
      }
      case 'bigquery': {
        return res(
          ctx.status(200),
          ctx.json(responseBigQueryAccountMappingEncryption),
        );
      }
      case 'databricks': {
        return res(
          ctx.status(200),
          ctx.json(responseDatabricksAccountMappingEncryption),
        );
      }
      default: {
        return res(
          ctx.status(400),
          ctx.json(errorWizardGeneralError),
        );
      }
    }

  }),

  //  account mapping
  rest.post(findRequestURL('accMappingURI'), (req, res, ctx) => {
    const data: any = req.body['data'] || null;
    const storageName: string = data.attributes.storage;
    switch (storageName) {
      case 'athena': {
        return res(
          ctx.status(200),
          ctx.json(responseAthenaAccountMapping),
        );
      }
      case 'azure_blob': {
        return res(
          ctx.status(201),
          ctx.json(responseBlobAccountMapping),
        );
      }
      case 'azure_datalake': {
        return res(
          ctx.status(201),
          ctx.json(responseDataLakeAccountMapping),
        );
      }
      case 'snowflake': {
        return res(
          ctx.status(201),
          ctx.json(responseSnowflakeAccountMapping),
        );
      }
      case 'redshift': {
        return res(
          ctx.status(201),
          ctx.json(responseRedshiftAccountMapping),
        );
      }
      case 'bigquery': {
        return res(
          ctx.status(201),
          ctx.json(responseBigQueryAccountMapping),
        );
      }
      case 'databricks': {
        return res(
          ctx.status(201),
          ctx.json(responseDatabricksAccountMapping),
        );
      }
      default: {
        return res(
          ctx.status(400),
          ctx.json(errorWizardGeneralError),
        );
      }
    }

  }),

  //  storages
  rest.post(findRequestURL('storagesURI'), (req, res, ctx) => {
    const data: any = req.body['data'] || null;
    const storageName: string = data.attributes.storage_type;

    switch (storageName) {
      case 'athena': {
        return res(
          ctx.status(200),
          ctx.json(responseAthenaStorages),
        );
      }
      case 'azure_blob': {
        return res(
          ctx.status(201),
          ctx.json(responseBlobStorages),
        );
      }
      case 'azure_datalake': {
        return res(
          ctx.status(201),
          ctx.json(responseDataLakeStorages),
        );
      }
      case 'snowflake': {
        return res(
          ctx.status(201),
          ctx.json(responseSnowflakeStorages),
        );
      }
      case 'redshift': {
        return res(
          ctx.status(201),
          ctx.json(responseRedshiftStorages),
        );
      }
      case 'bigquery': {
        return res(
          ctx.status(201),
          ctx.json(responseBigQueryStorages),
        );
      }
      case 'databricks': {
        return res(
          ctx.status(201),
          ctx.json(responseDatabricksStorages),
        );
      }
      default: {
        return res(
          ctx.status(400),
          ctx.json(errorWizardGeneralError),
        );
      }
    }

  }),

  //  storage
  rest.get(findRequestURL('storagesURI') + '/:id', (req, res, ctx) => {
    const id = req.params.id;

    switch (+id) {
      case 941: {
        return res(
          ctx.status(200),
          ctx.json(responseAthenaStorage),
        );
      }
      case 958: {
        return res(
          ctx.status(200),
          ctx.json(responseBlobStorage),
        );
      }
      case 959: {
        return res(
          ctx.status(200),
          ctx.json(responseDataLakeStorage),
        );
      }
      case 1014: {
        return res(
          ctx.status(200),
          ctx.json(responseSnowflakeStorage),
        );
      }
      case 1015: {
        return res(
          ctx.status(200),
          ctx.json(responseDatabricksStorage),
        );
      }
      case 1016: {
        return res(
          ctx.status(200),
          ctx.json(responseRedshiftStorage),
        );
      }
      case 972: {
        return res(
          ctx.status(200),
          ctx.json(responseBigQueryStorage),
        );
      }
      default: {
        return res(
          ctx.status(400),
          ctx.json(errorWizardGeneralError),
        );
      }
    }

  }),

  //  encryption
  rest.post(findRequestURL('encryptURI'), (req, res, ctx) => {
    const data: any = req.body['data'] || null;

    if (data.attributes.connectionString === 'DefaultEndpointsProtocol=https;AccountName=blobtest;AccountKey=xyz;EndpointSuffix=xyz') {
      return res(
        ctx.status(200),
        ctx.json(responseBlobEncryption),
      );
    }
    else if (data.attributes.connectionString === 'DefaultEndpointsProtocol=https;AccountName=datalaketest;AccountKey=xyz;EndpointSuffix=xyz') {
      return res(
        ctx.status(200),
        ctx.json(responseDataLakeEncryption),
      );
    }
    else if (data.attributes.awsSecretKey === 'awsAthenaSecretKey') {
      return res(
        ctx.status(200),
        ctx.json(responseAthenaEncryption),
      );
    }
    else if (data.attributes.password === 'snowflakePswd') {
      return res(
        ctx.status(200),
        ctx.json(responseSnowflakeEncryption),
      );
    }
    else if (data.attributes.password === 'awsRedshiftPassword') {
      return res(
        ctx.status(200),
        ctx.json(responseRedshiftEncryption),
      );
    }
    else if (data.attributes.serviceAccount) {
      // big query after account validation
      return res(
        ctx.status(200),
        ctx.json(responseBigQueryValidationEncryption),
      );
    }
    else if (data.attributes.accessToken === 'databricks1access1token') {
      return res(
        ctx.status(200),
        ctx.json(responseDatabricksEncryption),
      );
    }
    else {
      return res(
        ctx.status(400),
        ctx.json(errorWizardGeneralError),
      );
    }

  }),

  // add new subscription
  rest.post(findRequestURL('subscriptionURI'), (req, res, ctx) => {
    const data: any = req.body['data'] || null;
    let destinationName: string = data.attributes.name;

    const destinationExist: boolean = findSubscriptionFromSubscriptionName(destinationName);
    if (destinationExist) {
      destinationName = 'destinationExist';
    }

    switch (destinationName) {
      case 'aws-athena-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseAthenaSubscription),
        );
      }
      case 'azure-blob-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseBlobSubscription),
        );
      }
      case 'azure-data-lake-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseDataLakeSubscription),
        );
      }
      case 'snowflake-warehouse-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseSnowflakeSubscription),
        );
      }
      case 'aws-redshift-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseRedshiftSubscription),
        );
      }
      case 'amazon-advertizing-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseAmazonAdvertisingSubscription),
        );
      }
      case 'amazon-attribution-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseAmazonAttributionSubscription),
        );
      }
      case 'amazon-finance-realtime-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseSpSubscription),
        );
      }
      case 'amazon-fees-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseSpSubscription),
        );
      }
      case 'amazon-fulfillment-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseSpSubscription),
        );
      }
      case 'amazon-inventory-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseSpSubscription),
        );
      }
      case 'amazon-inventory-realtime-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseSpSubscription),
        );
      }
      case 'amazon-sales-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseSpSubscription),
        );
      }
      case 'amazon-orders-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseSpSubscription),
        );
      }
      case 'amazon-settlement-reports-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseSpSubscription),
        );
      }
      case 'amazon-inbound-fulfillment-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseSpSubscription),
        );
      }
      case 'google-big-query-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseBigQuerySubscription),
        );
      }
      case 'databricks-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseDatabricksSubscription),
        );
      }
      case 'amazon-dsp-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseAmazonDSPSubscription),
        );
      }
      case 'facebook-marketing-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseFacebookMarketingSubscription),
        );
      }
      case 'facebook-common-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseFacebookCommonSubscription),
        );
      }
      case 'google-ads-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseGoogleAdsSubscription),
        );
      }
      case 'google-campaign-manager-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseGoogleCampaignManagerSubscription),
        );
      }
      case 'google-analytics-360-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseGoogleAnalytics360Subscription),
        );
      }
      case 'google-search-ads-360-success': {
        return res(
          ctx.delay(1000),
          ctx.status(201),
          ctx.json(responseGoogleSearchAds360Subscription),
        );
      }
      default: {
        if (destinationName === 'destinationExist') {
          return res(
            ctx.delay(3000),
            ctx.status(400),
            ctx.json(responseSubscriptionExistError),
          );
        } else {
          return res(
            ctx.status(400),
            ctx.json(errorWizardGeneralError),
          );
        }
      }
    }
  }),

  // delete subscription
  // rest.patch(findRequestURL('subscriptionURI') + '/:id', (req, res, ctx) => {
  //   const id = req.params.id;
  //   const responseRemoveSubscription = findSubscriptionFromSubscriptionId(id);
  //   return res(
  //     ctx.delay(3000),
  //     ctx.status(200),
  //     ctx.json(responseRemoveSubscription),
  //   );
  // }),

  // edit subscription
  rest.patch(findRequestURL('subscriptionURI') + '/:id', (req, res, ctx) => {
    const id = req.params.id;
    if (id === '629') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseAmazonAdvertizingEditSubscription),
      );
    }
    else if (id === '727') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseAmazonAttributionEditSubscription),
      );
    }
    else if (id === '760') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseGoogleAdsEditSubscription),
      );
    }
    else if (id === '761') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseAmazonDspEditSubscription),
      );
    }
    else if (id === '762') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseAmazonFeesEditSubscription),
      );
    }
    else if (id === '763') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseAmazonFinanceRealtimeEditSubscription),
      );
    }
    else if (id === '793') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseAmazonFulfillmentRealtimeEditSubscription),
      );
    }
    else if (id === '794') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseAmazonInboundFulfillmentEditSubscription),
      );
    }
    else if (id === '795') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseInstagramInsightsEditSubscription),
      );
    }
    else if (id === '859') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseAmazonInventoryEditSubscription),
      );
    }
    else if (id === '892') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseAmazonInventoryRealtimeEditSubscription),
      );
    }
    else if (id === '893') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseFacebookMarketingEditSubscription),
      );
    }
    else if (id === '958') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseAmazonOrdersEditSubscription),
      );
    }
    else if (id === '991') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseFacebookPageInsightsEditSubscription),
      );
    }
    else if (id === '992') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseAmazonSalesEditSubscription),
      );
    }
    else if (id === '993') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseAmazonSettlementReportsEditSubscription),
      );
    }
    else if (id === '994') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseInstagramStoriesEditSubscription),
      );
    }
    else if (id === '165') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseAthenaEditSubscription),
      );
    }
    else if (id === '163') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseDataLakeEditSubscription),
      );
    }
    else if (id === '162') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseBlobEditSubscription),
      );
    }
    else if (id === '276') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseSnowflakeEditSubscription),
      );
    }
    else if (id === '277') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseDatabricksEditSubscription),
      );
    }
    else if (id === '138') {
      return res(
        ctx.delay(1500),
        ctx.status(200),
        ctx.json(responseBigQueryEditSubscription),
      );
    }
    else {
      return res(
        ctx.status(400),
        ctx.json('something went wrong with editing subscription !!'),
      );
    }
  }),

  // subscription spm (edit)
  rest.get(findRequestURL('subscriptionSpm'), (req, res, ctx) => {
    const subscription = req.url.searchParams.get('subscription');

    if (subscription === '629') {
      return res(
        ctx.status(200),
        ctx.json(responseAmazonAdvertizingSpm),
      );
    }
    else if (subscription === '727') {
      return res(
        ctx.status(200),
        ctx.json(responseAmazonAttributionSpm),
      );
    }
    else if (subscription === '760') {
      return res(
        ctx.status(200),
        ctx.json(responseGoogleAdsSpm),
      );
    }
    else if (subscription === '761') {
      return res(
        ctx.status(200),
        ctx.json(responseAmazonDspSpm),
      );
    }
    else if (subscription === '762') {
      return res(
        ctx.status(200),
        ctx.json(responseAmazonFeesSpm),
      );
    }
    else if (subscription === '763') {
      return res(
        ctx.status(200),
        ctx.json(responseAmazonFinanceRealtimeSpm),
      );
    }
    else if (subscription === '793') {
      return res(
        ctx.status(200),
        ctx.json(responseAmazonFulfillmentRealtimeSpm),
      );
    }
    else if (subscription === '794') {
      return res(
        ctx.status(200),
        ctx.json(responseAmazonInboundFulfillmentSpm),
      );
    }
    else if (subscription === '795') {
      return res(
        ctx.status(200),
        ctx.json(responseInstagramInsightsSpm),
      );
    }
    else if (subscription === '859') {
      return res(
        ctx.status(200),
        ctx.json(responseAmazonInventorySpm),
      );
    }
    else if (subscription === '892') {
      return res(
        ctx.status(200),
        ctx.json(responseAmazonInventoryRealtimeSpm),
      );
    }
    else if (subscription === '893') {
      return res(
        ctx.status(200),
        ctx.json(responseFacebookMarketingSpm),
      );
    }
    else if (subscription === '958') {
      return res(
        ctx.status(200),
        ctx.json(responseAmazonOrdersSpm),
      );
    }
    else if (subscription === '991') {
      return res(
        ctx.status(200),
        ctx.json(responseFacebookPageInsightsSpm),
      );
    }
    else if (subscription === '992') {
      return res(
        ctx.status(200),
        ctx.json(responseAmazonSalesSpm),
      );
    }
    else if (subscription === '993') {
      return res(
        ctx.status(200),
        ctx.json(responseAmazonSettlementReportsSpm),
      );
    }
    else if (subscription === '994') {
      return res(
        ctx.status(200),
        ctx.json(responseInstagramStoriesSpm),
      );
    }
    else if (subscription === '165') {
      return res(
        ctx.status(200),
        ctx.json(responseAthenaSpm),
      );
    }
    else if (subscription === '163') {
      return res(
        ctx.status(200),
        ctx.json(responseDataLakeSpm),
      );
    }
    else if (subscription === '162') {
      return res(
        ctx.status(200),
        ctx.json(responseBlobSpm),
      );
    }
    else if (subscription === '276') {
      return res(
        ctx.status(200),
        ctx.json(responseSnowflakeSpm),
      );
    }
    else if (subscription === '277') {
      return res(
        ctx.status(200),
        ctx.json(responseDatabricksSpm),
      );
    }
    else if (subscription === '138') {
      return res(
        ctx.status(200),
        ctx.json(responseBigQuerySpm),
      );
    }
    else {
      return res(
        ctx.status(200),
        ctx.json(errorWizardGeneralError),
      );
    }
  }),

  // big-query account validation
  rest.get(findRequestURL('bigQueryAccountValidationURI'), (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json(responseBigQueryValidation),
    );
  }),

  // remote identity
  rest.post(findRequestURL('baseIdentitiesURL'), (req, res, ctx) => {
    const data: any = req.body['data'] || null;
    return res(
      ctx.status(201),
      ctx.json(responseBigQueryRemoteIdentity),
    );
  }),

  // big-query remote identity meta
  rest.get(findRequestURL('remoteIdentityMetaEndpoint'),
    (req, res, ctx) => {
      const remoteIdentity = req.url.searchParams.get('remote_identity');
      const remoteIdentityTypeMetaKey = req.url.searchParams.get('remoteidentitytypemetakey');
      return res(
        ctx.status(200),
        ctx.json(responseBigQueryRemoteIdentityMeta),
      );
    }),

  // big-query dataset
  rest.get(findRequestURL('serviceApiEndpoint') + 'bq/datasets/:id',
    (req, res, ctx) => {
      const id = req.params.id;
      return res(
        ctx.status(200),
        ctx.json(responseBigQueryDataSet),
      );
    }),

  // amazon profiles-only
  rest.get(findRequestURL('serviceApiEndpoint') + 'amzadv/profiles-only/:id',
    (req, res, ctx) => {
      const profileTypes = req.url.searchParams.get('profile_types');
      const id = req.params.id;

      if (id === '22' && profileTypes === 'seller,vendor') {
        return res(
          ctx.status(200),
          ctx.json(responseAmazonAdvertisingProfiles),
        );
      }
      else if (id === '112' && profileTypes === 'attribution') {
        return res(
          ctx.status(200),
          ctx.json(responseAmazonAttributionProfiles),
        );
      }
      else if (id === '22' && profileTypes === 'dsp') {
        return res(
          ctx.status(200),
          ctx.json(responseAmazonDSPProfiles),
        );
      }
      else {
        return res(
          ctx.status(400),
          ctx.json(errorWizardGeneralError),
        );
      }
    }),

  // amazon brands
  rest.get(findRequestURL('serviceApiEndpoint') + 'amzadv/brands/:id',
    (req, res, ctx) => {
      const profiles = req.url.searchParams.get('profiles');
      const id = req.params.id;

      if (id === '22' && profiles === '586011285459526,2831667069998299') {
        return res(
          ctx.status(200),
          ctx.json(responseAmazonAdvertisingBrands),
        );
      }
      else if (id === '112' && profiles === '2401846814837028,3612007981467236,2116350962252689') {
        return res(
          ctx.status(200),
          ctx.json(responseAmazonAttributionBrands),
        );
      }
      else if (id === '112' && profiles === '801268442976258') {
        return res(
          ctx.status(200),
          ctx.json(responseAmazonAttributionBrands2),
        );
      }
      else if (id === '22' && profiles === '693136736383016') {
        return res(
          ctx.status(200),
          ctx.json(responseAmazonDSPBrands),
        );
      }
      else {
        return res(
          ctx.status(400),
          ctx.json(errorWizardGeneralError),
        );
      }
    }),

  // amazon attribution report test
  rest.get(findRequestURL('serviceApiEndpoint') + 'amzadv/attribution-report-test/:id/:profileId',
    (req, res, ctx) => {
      const id = req.params.id;
      const profileId = req.params.profileId;
      return res(
        ctx.delay(5000),
        ctx.status(200),
        ctx.json(responseAmazonAttributionReportTest),
      );
      // if (id === '112') {
      //   return res(
      //     ctx.delay(5000),
      //     ctx.status(200),
      //     ctx.json(responseAmazonAttributionReportTest),
      //   );
      // }
      // else {
      //   return res(
      //     ctx.status(400),
      //     ctx.json(errorWizardGeneralError),
      //   );
      // }
    }),

  // amazon SP products
  rest.get(findRequestURL('serviceApiEndpoint') + 'sp/marketplaces/:id',
    (req, res, ctx) => {
      const id = req.params.id;
      if (id === '135') {
        return res(
          ctx.status(200),
          ctx.json(responseSpMarketplaces),
        );
      }
      // else if (id === '168') {
      //   return res(
      //     ctx.status(200),
      //     ctx.json(responseSpMarketplaces2),
      //   );
      // }
      // else if (id === '214') {
      //   return res(
      //     ctx.status(200),
      //     ctx.json(responseSpMarketplaces3),
      //   );
      // }
      else {
        return res(
          ctx.status(400),
          ctx.json(errorWizardGeneralError),
        );
      }
    }),


  // facebook marketing profiles
  rest.get(findRequestURL('serviceApiEndpoint') + 'facebook/ads-profiles/:id',
    (req, res, ctx) => {
      const id = req.params.id;
      if (id === '202') {
        return res(
          ctx.status(200),
          ctx.json(responseFacebookMarketingProfiles),
        );
      }
      else {
        return res(
          ctx.status(400),
          ctx.json(errorWizardGeneralError),
        );
      }
    }),

  // facebook common profiles
  rest.get(findRequestURL('serviceApiEndpoint') + 'facebook/page-profiles/:id',
    (req, res, ctx) => {
      const id = req.params.id;
      if (id === '202') {
        return res(
          ctx.status(200),
          ctx.json(responseFacebookCommonProfiles),
        );
      }
      else {
        return res(
          ctx.status(400),
          ctx.json(errorWizardGeneralError),
        );
      }
    }),


  // google-ads customer-list
  rest.get(findRequestURL('serviceApiEndpoint') + 'googleads/list-customers/:id',
    (req, res, ctx) => {
      const id = req.params.id;

      if (id === '178') {
        return res(
          ctx.status(200),
          ctx.json(responseGoogleAdsCustomerList),
        );
      }
      else {
        return res(
          ctx.status(400),
          ctx.json(errorWizardGeneralError),
        );
      }
    }),

  // google-ads managed-list
  rest.get(findRequestURL('serviceApiEndpoint') + 'googleads/list-managed/:id/:managedId',
    (req, res, ctx) => {

      const id = req.params.id;
      const managedId = req.params.managedId;

      return res(
        ctx.status(200),
        ctx.json(responseGoogleAdsCustomerListManaged),
      );

      // if (id === '178' && managedId === '4156813945') {
      //   return res(
      //     ctx.status(200),
      //     ctx.json(responseGoogleAdsCustomerListManaged),
      //   );
      // }
      // else {
      //   return res(
      //     ctx.status(400),
      //     ctx.json(errorWizardGeneralError),
      //   );
      // }
    }),

  // healthchecks API
  rest.get(findRequestURL('healthCheckURL'),
    (req, res, ctx) => {
      const params = req.params;
      const searchParams = req.url.searchParams;
      return res(
        ctx.delay(3000),
        ctx.status(200),
        ctx.json(responseHealthchecksData),
      );

    }),

  // google-campaign-manager profiles
  rest.get(findRequestURL('serviceApiEndpoint') + 'gcm/profiles/:id',
    (req, res, ctx) => {
      const id = req.params.id;

      if (id === '216') {
        return res(
          ctx.status(200),
          ctx.json(responseGoogleCampaignManagerProfiles),
        );
      }
      else {
        return res(
          ctx.status(400),
          ctx.json(errorWizardGeneralError),
        );
      }
    }),

  // google-campaign-manager reports
  rest.get(findRequestURL('serviceApiEndpoint') + 'gcm/reports/:id',
    (req, res, ctx) => {
      const id = req.params.id;
      const profileId = req.url.searchParams.get('profile_id');

      return res(
        ctx.status(200),
        ctx.json(responseGoogleCampaignManagerReports),
      );

    }),

  // google-analytics-360 project-list
  rest.get(findRequestURL('serviceApiEndpoint') + 'bq/projects/:id',
    (req, res, ctx) => {
      const id = req.params.id;

      if (id === '216') {
        return res(
          ctx.status(200),
          ctx.json(responseGoogleAnalytics360Projects),
        );
      }
      else {
        return res(
          ctx.status(400),
          ctx.json(errorWizardGeneralError),
        );
      }
    }),

  // google-analytics-360 dataset-list
  rest.get(findRequestURL('serviceApiEndpoint') + 'bq/project-datasets/:id',
    (req, res, ctx) => {
      const id = req.params.id;
      const projectId = req.url.searchParams.get('project_id');

      return res(
        ctx.status(200),
        ctx.json(responseGoogleAnalytics360Datasets),
      );
    }),

  // GET /service/bq/ga-table-exists/<remote_identity_id>?project_id=<project_id>&dataset_id=<dataset_id>
  rest.get(findRequestURL('serviceApiEndpoint') + 'bq/ga-table-exists/:remote_identity_id',
    (req, res, ctx) => {
      const remoteIdentityId = req.params.remote_identity_id;
      const projectId = req.url.searchParams.get('project_id');
      const datasetId = req.url.searchParams.get('dataset_id');

      return res(
        ctx.status(200),
        ctx.json(responseGoogleAnalytics360GaTableExists),
      );
    }),

  // google-search-ads-360 agency
  rest.get(findRequestURL('serviceApiEndpoint') + 'gsa/agency/:id',
    (req, res, ctx) => {
      const id = req.params.id;

      if (id === '216') {
        return res(
          ctx.status(200),
          ctx.json(responseGoogleSearchAds360Profiles),
        );
      }
      else {
        return res(
          ctx.status(400),
          ctx.json(errorWizardGeneralError),
        );
      }
    }),
];



