import { Integration } from '../../models/integration.model';
import { vendors } from '../logos';
import {
  productIdFacebookMarketing, productIdFacebookPageInsights, productIdInstagramInsights, productIdInstagramStories
} from '../product-ids';

export const integrationFacebookMarketing: Integration = {
  productId: productIdFacebookMarketing,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Facebook',
  name: 'Marketing',
  badge: 'none',
  type: 'source',
  sortOrder: 400,
  tagLine: 'Facebook Ads statistics for attribution insights, reporting and analytics ',
  routerLink: '/wizards/facebook-marketing',
  logoPath: vendors.facebook,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 2,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'dev.facebook.com/marketing',
    uri: 'https://developers.facebook.com/docs/marketing-apis/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const integrationFacebookPageInsights: Integration = {
  productId: productIdFacebookPageInsights,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Facebook',
  name: 'Page Insights',
  badge: 'none',
  type: 'source',
  sortOrder: 500,
  tagLine: 'Engagement, reach, and audience insights for Facebook Pages and Page posts',
  routerLink: '/wizards/facebook-page-insights',
  logoPath: vendors.facebook,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 2,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'dev.facebook.com/graph-api',
    uri: 'https://developers.facebook.com/docs/graph-api/'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const integrationInstagramInsights: Integration = {
  productId: productIdInstagramInsights,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Instagram',
  name: 'Insights',
  badge: 'none',
  type: 'source',
  sortOrder: 1000,
  tagLine: 'Perpformance insights data for users and media organic social interactions',
  routerLink: '/wizards/instagram-business-iq',
  logoPath: vendors.instagram,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 2,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'facebook.com/instagram-api',
    uri: 'https://developers.facebook.com/docs/instagram-api/guides/insights'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const integrationInstagramStories: Integration = {
  productId: productIdInstagramStories,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Instagram',
  name: 'Stories',
  badge: 'none',
  type: 'source',
  sortOrder: 1100,
  tagLine: 'Get the latest performance insights for a story before they expire every 24 hours',
  routerLink: '/wizards/instagram-stories',
  logoPath: vendors.instagram,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 2,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'facebook.com/instagram-api',
    uri: 'https://developers.facebook.com/docs/instagram-api/guides/insights'
  },
  manageMenu: {
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false
};
