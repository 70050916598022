import { PipelineCountService } from './../../services/pipeline-count.service';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { dailyPipelinesGet, dailyPipelinesLoad } from '../actions/account.actions';
import { DateTimeService, DATE_FORMAT_YYYY_MM_DD } from '../../services/date-time.service';

@Injectable()
export class DailyPipelinesEffects {

    dailyPipelinesGet$ = createEffect(() => this.actions$
        .pipe(
            ofType(dailyPipelinesGet),
            tap(async action => {
                try {
                    const self = this;
                    const today = new Date();
                    const pipeLineCountDate = this.dateTimeService.subtractDays(new Date(today), 30, DATE_FORMAT_YYYY_MM_DD);
                    const response = await this.pipelineCountService.getByUTCDate(pipeLineCountDate);
                    const dates = this.pipelineCountService.getDateRangeArray
                        (new Date(pipeLineCountDate), new Date(today), DATE_FORMAT_YYYY_MM_DD);
                    const data = new Array(dates.length).fill(0);

                    response.body['data'].forEach((value) => {
                        const dateIdx = dates.indexOf(value.attributes.date);
                        data[dateIdx] = value.attributes.quantity;
                    });

                    this.store$.dispatch(dailyPipelinesLoad({
                        dailyPipelines: {
                            labels: dates,
                            data: data
                        }
                    }));
                }
                catch (error) {
                    throw error;
                }
            })
        ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private pipelineCountService: PipelineCountService,
        private store$: Store<AppState>,
        private dateTimeService: DateTimeService

    ) { }

}