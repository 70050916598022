import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { errorIntegrationDoesNotSupportHistory, errorWizardGeneralError } from 'src/app/shared/constants/flash-notifications';
import { subscriptionEventMetaData } from 'src/app/shared/constants/intercom-event-metadata-types';
import { IntegrationService } from 'src/app/shared/services/integration.service';
import { environment } from 'src/environments/environment';
import { AppState } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { Integration } from 'src/app/shared/models/integration.model';
import { IntercomService } from 'src/app/shared/services/intercom.service';
import { BaseWizardService } from 'src/app/modules/wizards/base-wizard.service';
import { modalMessageHistoryCancel, modalTitleHistoryCancel } from 'src/app/shared/constants/modal-titles-and-messages';
import { modalsClose, modalsOpen } from '../store/actions/modals.action';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  modalStatus$ = new Subject<string>();

  constructor(
    private http: HttpClient,
    private integrationService: IntegrationService,
    private intercomService: IntercomService,
    protected store$: Store<AppState>,
    protected wizardService: BaseWizardService
  ) { }

  async requestHistory(subscriptionId: number, integrationId: number, subProductId: string,
    historyApiStartDate: string, historyApiEndDate: string): Promise<void> {

    const delay = ms => new Promise(res => setTimeout(res, ms));
    await delay(2000);

    const integration = this.integrationService.findIntegrationFromId(integrationId);

    // handle this error differently.
    // if (!integration.history) {
    //   this.modalStatus$.next('modal-history-request-completed');
    //   throw errorIntegrationDoesNotSupportHistory;
    // }

    const payload = {
      data: {
        type: 'HistoryTransaction',
        attributes: {
          product_id: integrationId,
          start_date: historyApiStartDate,
          end_date: historyApiEndDate,
          is_primary: false
        }
      }
    };

    try {
      const response = await this.http.post(environment.openbridgeApiUris.service + '/service/history/' +
        environment.openbridgeApiUris.partialIdentifierTwo + '/history/' +
        subscriptionId, payload).toPromise();

      // Intercom history request success event
      this.dispatchIntercomSubscriptionEvent(integrationId, subProductId, subscriptionId,'request', 'success');

    }
    catch (error) {
      this.modalStatus$.next('modal-history-request-completed');

      // Intercom history request failure event
      this.dispatchIntercomSubscriptionEvent(integrationId, subProductId, subscriptionId, 'request', 'failure', error);

      throw JSON.stringify(error, null, 2);
    }

    this.modalStatus$.next('modal-history-request-completed');
  }

  async getProductHistoryInformation(): Promise<any> {
    const response = await this.http.get(environment.openbridgeApiUris.service + '/service/history/' + environment.openbridgeApiUris.partialIdentifierTwo + '/history/meta/max-request').toPromise();
    return response;
  }

  async cancelHistory(subscriptionId: string, integrationId: number, subProductId: string): Promise<void> {
    this.store$.dispatch(modalsOpen({
      modalType: 'progress',
      title: modalTitleHistoryCancel,
      message: `${modalMessageHistoryCancel} ${subscriptionId}`,
      progress: 0
    }));

    const payload = {
      "data": {
        "type": "HistoryTransaction",
        "id": subscriptionId,
        "attributes": { 
          "status": "cancelled"
        }
      }
    };

    const response = await this.http.patch(environment.openbridgeApiUris.service + '/service/history/' +
      environment.openbridgeApiUris.partialIdentifierTwo + '/history/status/' +
      subscriptionId, payload).toPromise();

    // Intercom history cancellation success.
    this.dispatchIntercomSubscriptionEvent(integrationId, subProductId, subscriptionId, 'cancel', 'success');

    this.store$.dispatch(modalsClose());
  }

  dispatchIntercomSubscriptionEvent(productId: any, subProductId: string, subscriptionId: any, historyMode: 'request' | 'cancel', status: 'success' | 'failure', error?: any | null): void {
    let integration: Integration | null = null;

    if(subProductId && subProductId !== 'default' ) {
      integration = this.integrationService.findIntegrationFromIdAndSubproductId(productId, subProductId);
    } 
    else {
      integration = this.integrationService.findIntegrationFromId(productId);
    }
    
    if(integration) {
      const subProductId = integration.subProductId ? integration.subProductId : 'default';      
      const productName = integration.brand + ' ' + integration.name;
      const eventDescription = 'history ' + historyMode + ' ' + status + ' event';
        
      const metaData: subscriptionEventMetaData = {
        productType: integration.type,
        mode: historyMode,
        eventDescription: eventDescription,
        isAbandon: false,
        productId: productId,
        subProductId: subProductId,
        productName: productName,
        subscriptionId: subscriptionId,
        status: status,
        error: JSON.stringify(error),
      };
  
      this.intercomService.dispatchIntercomSubscriptionEvent(metaData); 
      
    }
  }

}
