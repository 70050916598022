import { Integration } from '../../models/integration.model';
import { vendors } from '../logos';
import {
  productIdYoutubeChannelInsights, productIdYoutubeCompetitorChannels,
  productIdYoutubeCompetitorVideo, productIdYoutubeVideoInsights
} from '../product-ids';

export const integrationYoutubeChannelInsights: Integration = {
  productId: productIdYoutubeChannelInsights,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Youtube',
  name: 'Channel Insights',
  badge: 'none',
  type: 'source',
  sortOrder: 1700,
  tagLine: 'Channel level insights for user activity, traffic, playlists, devices, audience, and many more',
  routerLink: '/wizards/youtube-channel-insights',
  logoPath: vendors.youtube,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 1,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'google.com/youtube/reporting',
    uri: 'https://developers.google.com/youtube/reporting/v1/reports/channel_reports'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  manageMenu: {
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const integrationYoutubeCompetitorChannels: Integration = {
  productId: productIdYoutubeCompetitorChannels,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Youtube',
  name: 'Competitor Channels',
  badge: 'none',
  type: 'source',
  sortOrder: 1800,
  tagLine: 'Understand viewing statistics and trends for competitor channel.',
  routerLink: '/wizards/youtube-competitor',
  logoPath: vendors.youtube,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 1,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'google.com/youtube/channels',
    uri: 'https://developers.google.com/youtube/reporting/v1/reports/channel_reports'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  manageMenu: {
  },
  duplicationHashScheme: {
    type: 'multihash',
    multiHashkey: 'channel_ids'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const integrationYoutubeVideoInsights: Integration = {
  productId: productIdYoutubeVideoInsights,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Youtube',
  name: 'Video Insights',
  badge: 'none',
  type: 'source',
  sortOrder: 1900,
  tagLine: 'Video level insights user activity, traffic, playlists, devices, audience, and many more',
  routerLink: '/wizards/youtube-video-insights',
  logoPath: vendors.youtube,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 1,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'google.com/youtube/reporting',
    uri: 'https://developers.google.com/youtube/reporting'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  manageMenu: {
  },
  duplicationHashScheme: {
    type: 'common'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false
};

export const integrationYoutubeCompetitorVideo: Integration = {
  productId: productIdYoutubeCompetitorVideo,
  subProductId: "default",
  enabled: true,
  primary: true,  
  comingSoon: false,
  isConfigurable: true,
  brand: 'Youtube',
  name: 'Competitor Video',
  badge: 'none',
  type: 'source',
  sortOrder: 2000,
  tagLine: 'Competitor video traffic, views, metadata, and many more',
  routerLink: '/wizards/youtube-video-iq',
  logoPath: vendors.youtube,
  frequency: 'Daily',
  standardIntegration: true,
  premiumIntegration: false,
  opensourceIntegration: false,
  remoteIdentityTypeId: 1,
  docs: {
    text: 'docs.openbridge.com',
    uri: 'https://docs.openbridge.com'
  },
  vendor: {
    text: 'google.com/youtube/video',
    uri: 'https://developers.google.com/youtube/v3/docs/videos'
  },
  oauthAppConfig: {
    allowOpenbridgeOauthApp: true,
    allowBringYourOwnOauthApp: false
  },
  manageMenu: {
  },
  duplicationHashScheme: {
    type: 'multihash',
    multiHashkey: 'channel_ids'
  },
  wizardConfigParams: {
    stages: {
      default: [
        'checklist',
        'identity',
        'details',
        'destination',
        'integrationName',
        'save'
      ]
    }
  },
  includeRegions: false
};