import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const DATE_FORMAT_MMM_DD_YYYY = 'MMM DD, YYYY';
export const DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
export const DATE_FORMAT_MMM_DD = 'MMM-DD';
export const DATE_TIME_START_OF_DAY = DATE_FORMAT_YYYY_MM_DD + ' 00:00:00';
export const DATE_TIME_END_OF_DAY = DATE_FORMAT_YYYY_MM_DD + ' 23:59:59';

@Injectable({
  providedIn: 'root'
})

export class DateTimeService {

  constructor() { }

  format(date, newFormat: string, currentFormat?: string): any {
    if (currentFormat) {
      // dayjs(value, 'YYYY-MM-DD').format('MMM-DD')
      return dayjs(date, currentFormat).format(newFormat);
    }
    else {
      // dayjs(value).format('MMM-DD')
      return dayjs(date).format(newFormat);
    }
  }

  subtractDays(date, days, outputFormat: string): string {
    return dayjs(new Date(date)).subtract(days, 'day').format(outputFormat);
  }

  addDays(date, days, outputFormat: string): string {
    return dayjs(new Date(date)).add(days, 'day').format(outputFormat);
  }

  convertTimeStampToDate(timeStampDate, format: string): string {
    return dayjs(timeStampDate * 1000).format(format);
  }

  convertToUtcDate(date, format: string): string {
    return dayjs(date).utc(true).format(format);
  }

  convertTimeStampToEpoch(date: string | number): number {
    return dayjs(date).unix();
  }

  utcDateTime(format: string = ''): string {
    if(format !== '') {
      return dayjs.utc().format(format);
    }
    return dayjs.utc().format();
  }

}
