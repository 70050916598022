import { EnvironmentConfig } from './environement-config.interface';
import { productionPlanStandard, productionPlanPremium, productionPlanBusiness, productionTrialPlan } from '../app/shared/constants/stripe';
import { productionAppIdIntercom, testAppIdIntercom } from '../app/shared/constants/intercom';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// mock service worker

// globally start mock service worker
// import { worker } from '../mocks/browser';
// worker.start();

export const environment: EnvironmentConfig = {
  production: true,
  enableMock: false,
  maintenanceMode: false,
  accMapping: {
    bucketKey: 'ob-zeroadmin-prod'
  },
  auth0: {
    domain: 'login.openbridge.com',
    clientId: 'iiFZzLznww2re4WW64I2Te2HFUVXCRuk',
    audience: 'https://authentication.api.openbridge.io/auth',
    connectionId: 'con_TCup1lWRrLFexb9j',
    roleMap: {
      apiUser: 'rol_aDXhfTigEQ47WHmT'
    }
  },
  cache: {
    forceInvalidationAt: '1674741057',
    identitiesKey: '-cache-list-identities',
    lastLoginKey: '-cache-last-login',
    subscriptionsKey: '-cache-list-subscriptions',
    subscriptionsExpectedKeyCount: 23
  },
  openbridgeApiUris: {
    partialIdentifier: 'prod',
    partialIdentifierTwo: 'production',
    healtChecksPartialIdentifier: 'production',
    account: 'https://account.api.openbridge.io/account',
    auth0Proxy: 'http://localhost:3000/auth0/',
    identities: 'https://remote-identity.api.openbridge.io',
    oauth: 'https://oauth.api.openbridge.io/oauth',
    authentication: 'https://authentication.api.openbridge.io',
    oauthAppCreate: 'https://oauth.api.openbridge.io',
    pipelineCount: 'https://pipeline-count.api.openbridge.io',
    service: 'https://service.api.openbridge.io',
    state: 'https://state.api.openbridge.io/state',
    subscription: 'https://subscriptions.api.openbridge.io',
    user: 'https://user.api.openbridge.io/user'
  },
  s3BucketUris: {
    csvValidator: 'https://openbridge-api-service-csv-validator-production.s3.amazonaws.com/',
    airbyteConfig: ''
  },
  siteUpdate: {
    versionCheckIntervalLengthInMinutes: 3
  },
  stripe: {
    publishableKey: 'pk_live_lt7AKEJm9AY9J02V61JsF3uq',
    prices: {
      trialPlanId: productionTrialPlan,
      standardPriceId: productionPlanStandard,
      premiumPriceId: productionPlanPremium,
      businessPriceId: productionPlanBusiness,
      enterprisePriceId: 'enterprise'
    },
    name: {
      trial: 'Trial Plan',
      standard: 'Basic Plan',
      premium: 'Premium Plan',
      business: 'Business Plan',
      enterprise: 'Enterprise Plan'
    }
  },
  premiumEnabledPrices: {
    trialPlanId: true,
    standardPriceId: false,
    premiumPriceId: true,
    businessPriceId: true,
    enterprisePriceId: true
  },
  intercom: {
    appId: productionAppIdIntercom
  },
  httpInterceptor: {
    tokenExcludeDomains: [
      'https://login.openbridge.com',
      'https://openbridge-production.us.auth0.com',
      'https://openbridge-airbyte-config-temp-dev.s3.amazonaws.com/'
    ],
    tokenExcludeUrlExpressions: []
  },
  storageValidation: {
    timeBetweenPollsInMilliseconds: 4000,
    numberOfPollsBeforeFailure: 90
  },
  csvUploadValidation: {
    pollingTimeInSeconds: 180,
  },
  sftp: {
    host: 'sftp.production.openbridge.io'
  },
  organizations: {
    openbridgeSupportAuth0UserId: 'auth0|636a4261738c1d3f4d57ae6f'
  },

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
